import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { ArrowRight } from 'react-feather'

export interface Props {
  title?: string
  URL: string
}

export const Item = memo(function Item({ title, URL }: Props) {
  if (!URL) {
    return null
  }

  return (
    <Container to={URL}>
      {title ? <Title className="cta-title">{title}</Title> : null}
      <Arrow className="cta-arrow" dial={5}>
        <ArrowRight />
      </Arrow>
    </Container>
  )
})

const Container = styled(Link)`
  padding: 0 9.167vw;
  position: relative;
  text-align: center;
  &:nth-of-type(2) {
    &:before {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background: ${({ theme }) => theme.colors.variants.neutralLight2};
      opacity: 0.36;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &:hover {
    .cta-title {
      transform: translateY(-0.3125rem);
    }
    .cta-arrow {
      background: ${rgba(theme.colors.variants.neutralDark1, 0.05)};
      border-color: transparent;
    }
  }

  @media (max-width: 1023px) {
    padding: 0 5vw;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
    &:nth-of-type(2) {
      margin-top: 2.25rem;
      &:before {
        display: none;
      }
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.875rem;
  transition: 0.3s ease-in-out;
`

const Arrow = styled(FlexBox)`
  width: 5.25rem;
  height: 5.25rem;
  border: 1px solid ${rgba(theme.colors.variants.neutralDark1, 0.05)};
  border-radius: 50%;
  cursor: pointer;
  margin: 1.875rem auto 0;
  transition: 0.3s ease-in-out;
  svg {
    width: auto;
    height: 2.5rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark1};
    stroke-width: 1;
  }

  @media (max-width: 767px) {
    width: 3.75rem;
    height: 3.75rem;
    margin-top: 1.5rem;
  }
`
