import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
}

export const Welcome = memo(function Welcome({
  description,
  image,
  title,
}: Props) {
  return (
    <Container>
      <Icon1 />
      <Icon2 />
      <Icon3 />
      <Icon4 />
      <ParallaxProvider>
        <Parallax y={[-15, 15]}>
          <Wrapper>
            {title ? <Title>{title}</Title> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
          </Wrapper>
          {image ? (
            <Background>
              <LazyLoadImage effect="blur" {...image} />
            </Background>
          ) : null}
        </Parallax>
      </ParallaxProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 12.5rem 10vw 3.75rem;
  position: relative;

  @media (max-width: 1199px) {
    padding: 6rem 8.454vw;
  }

  @media (max-width: 1023px) {
    padding-bottom: 0;
    .parallax-inner {
      transform: none !important;
    }
  }

  @media (max-width: 767px) {
    padding-top: 11.25rem;
  }
`

const Wrapper = styled.div`
  max-width: 760px;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: auto;
  padding: 4.5rem 10rem 6.875rem;
  text-align: center;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 208px;
    height: 374px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    z-index: 3;
  }
  &:before {
    width: 207px;
    height: 291px;
    background-image: url('/mascotte-1.png');
    top: 115px;
    left: -103px;
  }
  &:after {
    width: 208px;
    height: 341px;
    background-image: url('/mascotte-2.png');
    bottom: -63px;
    right: -134px;
  }

  @media (max-width: 767px) {
    max-width: none;
    padding: 7.5rem 8.454vw 3.75rem;
    &:before,
    &:after {
      width: 110px;
      height: 200px;
    }
    &:before {
      top: -124px;
      left: calc(50% - 7.6875rem);
    }
    &:after {
      top: -100px;
      bottom: auto;
      right: calc(50% - 7.6875rem);
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 400;
  line-height: 5.25rem;

  @media (max-width: 767px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 3rem;
`

const Background = styled.div`
  max-width: 760px;
  margin: auto;
  padding-bottom: 21%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    padding-bottom: 44%;
  }
`

const Icon1 = styled.div`
  width: 10vw;
  height: 9.167vw;
  max-width: 144px;
  max-height: 132px;
  background: url('/shell.svg') no-repeat center;
  background-size: cover;
  position: absolute;
  top: 5.625rem;
  left: 6.25vw;
  transform: rotate(130deg);

  @media (max-width: 1023px) {
    display: none;
  }
`

const Icon2 = styled.div`
  width: 17.153vw;
  height: 13.472vw;
  max-width: 247px;
  max-height: 194px;
  background: url('/bucket.svg') no-repeat center;
  background-size: cover;
  position: absolute;
  top: 5.625rem;
  right: -1.181vw;
  transform: rotate(162deg);

  @media (max-width: 1023px) {
    display: none;
  }
`

const Icon3 = styled.div`
  width: 21.111vw;
  height: 20.625vw;
  max-width: 304px;
  max-height: 297px;
  background: url('/ball.svg') no-repeat center;
  background-size: cover;
  position: absolute;
  bottom: 110px;
  left: -7.986vw;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Icon4 = styled.div`
  width: 18.958vw;
  height: 17.958vw;
  max-width: 273px;
  max-height: 268px;
  background: url('/starfish.svg') no-repeat center;
  background-size: cover;
  position: absolute;
  bottom: -8.125rem;
  right: -2vw;
  transform: rotate(-39deg);

  @media (max-width: 1023px) {
    display: none;
  }
`
