import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  return (
    <Container dial={4} row>
      {icon ? (
        <Icon dial={5}>
          <SVG src={icon} width="18" height="18" alt={label} />
        </Icon>
      ) : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(FlexBox)``

const Icon = styled(FlexBox)`
  width: 3.75rem;
  height: 3.75rem;
  background: ${rgba(theme.colors.variants.neutralLight4, 0.1)};
  border-radius: 50%;
`

const SVG = styled.img`
  width: auto;
  max-width: 1.875rem;
  height: 1.5rem;
`

const Label = styled.label`
  width: calc(100% - 5rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  letter-spacing: 0.0175rem;
  line-height: 1.375rem;
  margin-left: 1.25rem;
`
