import styled from '@emotion/styled'
import { FamilyServices } from 'app/components/FamilyServices'
import { GalleryImages } from 'app/components/GalleryImages'
import { Hero } from 'app/components/Hero'
import { Intro } from 'app/components/Intro'
import { Letter } from 'app/components/Letter'
import { OffersBanner } from 'app/components/OffersBanner'
import { Review } from 'app/components/Review'
import { SEO } from 'app/components/SEO'
import { SimpleCTA } from 'app/components/SimpleCTA'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { TwoBlocks } from 'app/components/TwoBlocks'
import { Video } from 'app/components/Video'
import { Welcome } from 'app/components/Welcome'
import { FamilyQuotationForm } from 'app/containers/FamilyQuotationForm'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { Props as SidebarProps, Sidebar } from 'app/containers/Sidebar'
import {
  FamilyQuotationFormSenderBackend,
  FamilyQuotationFormSenderBackendConfiguration,
} from 'app/utils/FamilyQuotationFormSender'
import { PageProps } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  sidebarProps?: SidebarProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
  familyQuotationFormSenderBackendConfiguration: FamilyQuotationFormSenderBackendConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function FamilyPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  const sendFormToBackend = useMemo(
    () =>
      FamilyQuotationFormSenderBackend(
        pageContext.familyQuotationFormSenderBackendConfiguration,
      ),
    [pageContext.familyQuotationFormSenderBackendConfiguration],
  )

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.sidebarProps ? (
        <Sidebar
          languageCode={context.languageCode}
          {...context.sidebarProps}
        />
      ) : null}
      <GoogleAnalytics />
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? (
        <Intro variant="light" {...context.introProps} />
      ) : null}
      {context.letterProps ? <Letter {...context.letterProps} /> : null}
      {context.familyServicesProps ? (
        <FamilyServices {...context.familyServicesProps} />
      ) : null}
      {context.welcomeProps ? <Welcome {...context.welcomeProps} /> : null}
      {context.galleryImagesProps ? (
        <GalleryImages variant="clipLight" {...context.galleryImagesProps} />
      ) : null}
      {context.twoBlocksProps ? (
        <TwoBlocks {...context.twoBlocksProps} />
      ) : null}
      {context.videoProps ? <Video {...context.videoProps} /> : null}
      {context.reviewProps ? <Review {...context.reviewProps} /> : null}
      {context.offersBannerProps ? (
        <OffersBanner {...context.offersBannerProps} />
      ) : null}
      <FamilyQuotationForm
        {...(context.familyQuotationFormProps
          ? context.familyQuotationFormProps
          : null)}
        onFamilyQuotationFormSendToBackend={async (data) =>
          await sendFormToBackend(data)
        }
      />
      {context.simpleCTAProps ? (
        <SimpleCTA {...context.simpleCTAProps} />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
