// @ts-nocheck

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  ctas: ItemProps[]
}

export const SimpleCTA = memo(function SimpleCTA({ ctas }: Props) {
  if (!ctas) {
    return null
  }

  return (
    <Container dial={5} row stretch tag="section" wrap>
      {ctas.map((item, index) =>
        item.url ? (
          <Item key={index} title={item.label} URL={item.url} />
        ) : null,
      )}
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 6.125rem 10vw;

  @media (max-width: 1199px) {
    padding: 3.75rem 8.454vw;
  }

  @media (max-width: 767px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`
