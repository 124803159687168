import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo, useState } from 'react'
import { ChevronDown } from 'react-feather'
import Fade from 'react-reveal/Fade'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  languageCode: string
  services?: ServiceProps[]
  subtitle?: string
  title?: string
}

export const FamilyServices = memo(function FamilyServices({
  languageCode,
  services,
  subtitle,
  title,
}: Props) {
  if (!services) {
    return null
  }

  const [expandedServices, setExpandedServices] = useState(false)

  return (
    <Container dial={4} row tag="section" wrap>
      <LeftSide>
        {title ? (
          <Fade left distance="7.5rem">
            <Title>{title}</Title>
          </Fade>
        ) : null}
        {subtitle ? (
          <Fade left distance="7.5rem">
            <Subtitle>{subtitle}</Subtitle>
          </Fade>
        ) : null}
      </LeftSide>
      <RightSide>
        <Services
          className={
            services?.length > 6 && !expandedServices ? 'compact' : undefined
          }
          row
          wrap
        >
          {services.map((item, index) => (
            <Fade bottom distance="3.75rem" key={index}>
              <Service {...item} />
            </Fade>
          ))}
        </Services>
        {services?.length > 6 ? (
          <Expander
            className={expandedServices ? 'hidden' : undefined}
            onClick={() => setExpandedServices(true)}
          >
            {useVocabularyData('show-all', languageCode)}
            <ChevronDown />
          </Expander>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  min-height: 280px;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding-top: 8.125rem;
  position: relative;
  &:before {
    content: '';
    width: 18.958vw;
    height: 17.958vw;
    max-width: 273px;
    max-height: 268px;
    background: url('/starfish.svg') no-repeat center;
    background-size: cover;
    position: absolute;
    top: 1.25rem;
    left: -5vw;
    transform: rotate(-39deg);
  }

  @media (max-width: 1023px) {
    padding-top: 3.75rem;
    &:before {
      display: none;
    }
  }
`

const LeftSide = styled.div`
  width: 30%;
  padding-right: 3.75rem;
  padding-left: 10vw;

  @media (max-width: 1199px) {
    padding: 4.5rem 8.454vw;
  }

  @media (max-width: 1023px) {
    width: 100%;
    position: relative;
    &:before {
      content: '';
      width: 50%;
      height: 100%;
      background: url('/wave.svg') no-repeat;
      background-size: cover;
      opacity: 0.16;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  line-height: 1.875rem;
`

const RightSide = styled(FlexBox)`
  width: 70%;
  margin-top: -1.5rem;
  padding-right: 2.361vw;

  @media (max-width: 1199px) {
    padding: 0 8.454vw;
  }

  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    margin-top: 2.5rem;
  }
`

const Services = styled(FlexBox)`
  > div {
    width: calc(50% - 7.639vw);
    margin: 1.5rem 7.639vw 0 0;
    position: relative;
    z-index: 2;
  }

  @media (max-width: 767px) {
    &.compact {
      height: 22.5rem;
      overflow: hidden;
      position: relative;
      &:after {
        content: '';
        width: 100%;
        height: 6.25rem;
        background: linear-gradient(
          ${rgba(theme.colors.variants.primaryLight1, 0)},
          ${rgba(theme.colors.variants.primaryLight1, 1)}
        );
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
      }
    }
    > div {
      width: 100%;
      margin-right: 0;
    }
  }
`

const Expander = styled.div`
  display: none;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0875rem;
  line-height: 0.9375rem;
  margin-top: 1.875rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  &.hidden {
    display: none;
  }
  svg {
    display: block;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: auto;
  }

  @media (max-width: 767px) {
    display: block;
  }
`
