import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

export interface Props {
  claim?: string
  description?: string
  image?: string
  qualification?: string
  subtitle?: string
  title?: string
  user?: string
}

export const Letter = memo(function Letter({
  claim,
  description,
  image,
  qualification,
  subtitle,
  title,
  user,
}: Props) {
  return (
    <Container row stretch tag="section" wrap>
      <LeftSide dial={4}>
        {claim ? (
          <Fade left distance="7.5rem">
            <Claim>{claim}</Claim>
          </Fade>
        ) : null}
        {image ? (
          <BackgroundImage style={{ backgroundImage: `url(${image})` }} />
        ) : null}
      </LeftSide>
      <RightSide>
        <Fade>
          {title ? <Title>{title}</Title> : null}
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
          {user ? <User>{user}</User> : null}
          {qualification ? (
            <Qualification>{qualification}</Qualification>
          ) : null}
        </Fade>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 12.5rem 10vw;
  position: relative;
  .react-reveal {
    position: relative;
    z-index: 3;
  }

  @media (max-width: 1199px) {
    padding: 3.75rem 8.454vw;
  }

  @media (max-width: 1023px) {
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding-right: 9.931vw;
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
    padding-right: 0;
    padding-bottom: 50%;
  }

  @media (max-width: 767px) {
    padding-bottom: 75%;
  }
`

const Claim = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 200;
  line-height: 5.25rem;

  @media (max-width: 1023px) {
    display: none;
  }
`

const BackgroundImage = styled.div`
  width: 50%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const RightSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 3rem 3rem 3.75rem;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 2.25rem 1.875rem;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  line-height: 2.375rem;
  margin-bottom: 0.1875rem;
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin: 2.25rem 0;
`

const User = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.signature};
  font-size: 1.875rem;
  letter-spacing: -0.0938rem;
  line-height: 1.75rem;
`

const Qualification = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.75rem;
`
